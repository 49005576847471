@import 'src/styles/core.sass';
#form-contact {
  margin: -80px 15px 0 0;
  form {
    display: none;
    @include breakpoint($laptop) {
      border: 1px solid #E9E9E9;
      border-radius: 3px;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      height: max-content;
      position: sticky;
      top: 90px;
      margin-bottom: 10px;
      background-color: $white;
      .contact-company {
        font-weight: 700;
        line-height: 103%;
        color: #404A49;
        display: flex;
        justify-content: center;
        > h3 {
          margin: 0.5rem; }
        input {
          margin: 0;
          padding: 5px 10px;
          height: 100%;
          flex: 1 1 45%; } }
      input {
        margin: 0.5rem 0;
        border: 1px solid #DADADA; }
      input[type="checkbox"] {
        width: fit-content;
        margin-right: 0.5rem; }
      textarea {
        border: 1px solid #DADADA;
        background-color: $white;
        max-height: 150px;
        min-height: 9vh;
        padding: 10px 10px;
        margin: 8px 0;
        &:active {
          background-color: $white; }
        &::placeholder {
          color: $gray; } }
      .but-container {
        width: 100%;
        display: flex;
        justify-content: flex-end; }
      button {
        border-radius: 30px;
        padding: 0.75rem 2rem;
        margin: 1rem 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 125.5%;
        color: #FFFFFF;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg,#00957E 0%, #99B342 64.22%, #FDC71A 128.43%);
        &:only-child {
          margin-left: auto; } }
      .link {
        text-decoration: underline; } } }
  .check {
    font-size: $font-size-s; }
  footer {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between; }
  .success {
    color: $turquoise-p-ultralite; }
  .error {
    color: $magent; }
  .success, .error {
    margin-top: 1em;
    font-size: $font-size-m;
    font-weight: 500;
    text-align: center; } }
