@import 'src/styles/core.sass';
#page-footer {
  padding: 40px 20px;
  background-color: $green;
  color: white;
  .contact {
    font-weight: 400; }
  img {
    max-width: 200px; }
  .container {
    h2 {
      margin-bottom: 16px;
      font-size: $font-size-xl; }
    a {
      color: $white; } }
  .email {
    font-weight: 600;
    font-size: $font-size-l; }
  .items {
    font-weight: 400;
    margin-top: 60px;
    li {
      padding: 5px 0;
      cursor: pointer; }
    @include breakpoint($laptop) {
      margin-top: 60px;
      display: flex;
      gap: 4em; }
    @include breakpoint($desktop) {
      gap: 6em; } } }
