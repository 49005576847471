@import 'src/styles/core.sass';
#company-posts {
  position: relative;
  nav {
    background-color: $transparent;
    transition: all 0.5s ease-in-out; }
  section {
    padding: 1rem; }

  [id="company-posts-grid"] {
    margin: 30px 0; } }
