@import 'src/styles/core.sass';
#inscribe {
  section {
    padding: 1rem; }
  h3 {
    font-size: 27px;
    line-height: 125.5%;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  nav {
    background-color: $transparent;
    &.open {
      background-color: $magent;
      .searchbar {
        button {
          background: linear-gradient(90deg, #EC0163 0%, #F45F40 61.54%, #FDC71A 128.43%);
          span {
            color: $white; } } } } }
  > header {
    display: flex;
    align-items: center;
    min-height: $mobile;
    height: 75vh;
    max-height: $tablet;
    background-image: url("/images/inscribe-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 100vh rgba(black, 0.6);
    > div {
      height: 100%; }
    section {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #FFFFFF;
      max-width: 408px;
      @include breakpoint($laptop) {
        max-width: 700px; } }
    h2 {
      font-size: 27px;
      @include breakpoint($desktop) {
        font-size: $font-size-xxxl; } }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px; }
    @include breakpoint($tablet) {
      height: 800px;
      padding-bottom: 0; } }
  section.container {
    max-width: $laptop + 200px;
    @extend %push-auto; }
  .explanation {
    margin-top: 2rem;
    >h3 {
      font-weight: 700;
      font-size: 21px;
      line-height: 125.5%;
      color: #3B4342;
      @include breakpoint($laptop) {
        font-size: 45px; } }
    >p {
      max-width: 700px;
      color: #505050;
      font-weight: 400;
      font-size: 15px;
      line-height: 191%;
      text-align: start; } }
  .metodology, .explanation {
    .metodology-list {
      margin: 2rem 0; }
    .metodology-list-item {
      margin: 1rem 0;
      >div {
        display: none; } }
    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 179.02%;
      color: #505050;
      >strong {
        font-weight: 600;
        font-size: 14px;
        line-height: 191%; } } }
  @include breakpoint($tablet) {
    .metodology-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 4rem;
      .metodology-list-item {
        display: flex;
        gap: 1rem;
        >div {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 36px;
          height: 36px;
          border-radius: 50%;
          background-color: #FFEEF5;
          border: 1px solid $magent;
          >span {
            color: $magent; } } } } }
  .requirements {
    background: #F9F9F9;
    h3 {
      text-align: center; }
    img {
      margin-top: 2rem; }
    .requirements-introduction {
      font-weight: 400;
      font-size: 14px;
      line-height: 191.02%;
      color: #364543;
      padding-bottom: 1rem;
      text-align: center; }
    .discounts {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      @include breakpoint($tablet) {
        flex-direction: row; }
      .associateds {
        background: #EFEFEF;
        border-radius: 9px;
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 1rem;
        padding: 2rem;
        text-align: center;
        .associated-type {
          font-weight: 400;
          font-size: 16px;
          line-height: 179.02%;
          color: #4E4C4C; }
        .iva {
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          color: #6B6B6B; }
        .price {
          font-weight: 600;
          font-size: 30px;
          line-height: 45px;
          color: #000000; }
        .discount {
          color: $magent; } } }
    .requirement {
      font-weight: 600;
      font-size: 20px;
      line-height: 125.5%;
      color: #364543;
      text-align: center;
      margin: 1.5rem 0;
      height: fit-content;
      >h4 {
        margin: 0; }
      >img {
        margin: 0; }
      @include breakpoint($laptop) {
        margin: 3rem 0; } }
    .requirement-list {
      background: #EFEFEF;
      border-radius: 9px;
      max-width: 713px;
      display: block;
      margin: 0 auto;
      padding: 1rem 2rem;
      text-align: left;
      list-style: circle;
      li {
        font-weight: 400;
        font-size: 14px;
        line-height: 179.02%;
        padding: 10px 0;
        @include breakpoint(tablet) {
          padding: 1.25rem; } } }
    @include breakpoint($tablet) {
      padding: 3rem 0;
      .requirements-introduction {
        display: block;
        margin: 0 auto;
        max-width: 500px; }
      .discounts {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        max-width: 713px;
        margin: 0 auto; }
      >.requirement {
        margin-top: 4rem; }
      >.requirement-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 1.5rem 5rem; } } }
  .solicitude {
    > h3, p {
      text-align: center; }
    > h3 {
      margin: 2em; }
    > p {
      margin: 2em auto;

      text-align: center;
      @include breakpoint(tablet) {
        max-width: 30%; } }
    #inscribe-form {
      border: 1px solid #DDDDDD;
      border-radius: 9px;
      padding: 1rem;
      &:invalid {
        button[type="submit"] {
          opacity: .5; } }
      > h4 {
        font-weight: 400;
        font-size: 20px;
        line-height: 125.5%;
        color: #364543;
        padding: 0 2rem 0 2rem;
        @include breakpoint($laptop) {
          font-size: 25px; } }
      input, select {
        background: #FFFFFF;
        border: 1px solid #DADADA;
        border-radius: 3px;
        &::placeholder {
          color: #B6B6B6; }
        &:hover {
          border: 1px solid #797979; }
        &:focus {
          border: 1px solid #797979;
          box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1); } }
      .inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include breakpoint($tablet) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 2rem;
          gap: 1rem; }
        label {
          font-weight: 300;
          font-size: 12px;
          line-height: 125.5%;
          color: #000000;
          input[type="checkbox"] {
            margin-top: 1rem;
            width: auto; } } }
      .custom-select {
        .flag {
          width: 32px;
          height: 32px;
          margin-right: 16px;
          & + span {
            margin-top: 4px; } }
        .custom-select-header {
          height: 100%;
          .custom-select-header-info, .custom-select-row {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            li {
              &:hover, &:focus {
                outline: none;
                background-color: #DADADA; } } } } } } }
  #page-footer {
    margin-top: 5em;
    background-color: $magent; }
  @include breakpoint($laptop) {
    h3 {
      font-size: $font-size-xxl; } } }
