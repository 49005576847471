@import 'src/styles/core.sass';
.manager-data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  >h4 {
    font-weight: 400;
    font-size: 20px;
    line-height: 125.5%;
    color: #364543;
    padding: 0 2rem 0 2rem;
    @include breakpoint($desktop) {
      font-size: 25px; } }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    input {
      background: #FFFFFF;
      border: 1px solid #DADADA;
      border-radius: 3px; }
    label {
      font-weight: 300;
      font-size: 12px;
      line-height: 125.5%;
      color: #000000;
      input[type="checkbox"] {
        margin-top: 1rem;
        width: auto; } } }
  @include breakpoint($laptop) {
    .inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 2rem; } } }
