.telephone-contact {
  display: flex;
  align-items: center;
  gap: 10px;
  .custom-select {
    flex: 0 0 40%;
    max-width: 150px;
    .flag {
      flex-shrink: 0;
      margin-right: 12px;
      & + span {
        margin-top: 4px; } }
    .custom-select-header {
      height: 100%;
      .custom-select-header-info, .custom-select-row {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; } } }
    .custom-select-list {
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } } } }
