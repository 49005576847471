@import 'src/styles/core.sass';
.custom-select {
  height: 47px;
  position: relative;
  user-select: none;
  cursor: pointer;
  .icon {
    margin-right: 16px;
    transform: rotate(0deg);
    transition: all .5s ease; }
  &:focus-within {
    ul {
      width: 100%;
      display: block;
      position: relative;
      margin-top: 45px;
      background-color: #FFFFFF;
      border: 1px solid #DADADA;
      border-top: 0;
      max-height: 30vh;
      overscroll-behavior-y: contain;
      overflow-y: auto;
      z-index: 1000; }
    .icon {
      transform: rotate(-180deg); }
    .custom-select-header {
      position: absolute; } }
  > .custom-select-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #DADADA; }
  .custom-select-header-info, .custom-select-row {
    display: flex;
    align-items: center;
    padding: 5px 10px; }
  ul {
    display: none;
    li {
      cursor: pointer;
      flex: 0;
      &:hover, &:focus {
        outline: none;
        background-color: #DADADA; } } } }
