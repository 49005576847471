@include font-face("icons", "/fonts/icons/icons", normal, normal);

[class^="icon-"], [class*=" icon-"] {
  font-family: "icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  // Better Font Rendering =========== //
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  vertical-align: baseline;
  text-decoration: inherit;

  text-align: center;
  // font-size: 120%

  // margin-right: .1em
  // Animation center compensation - margins should be symmetric remove if not needed
  // margin-left: .1em

  // Uncomment for 3D effect
  // text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3)

  // Avoid text selection
  user-select: none; }

.icon {
  font-family: "icons"; }

.icon-left:before {
  content: "\e900"; }

.icon-lens:before {
  content: "\e901"; }

.icon-check:before {
  content: "\e902"; }

.icon-up:before {
  content: "\e903"; }

.icon-down:before {
  content: "\e904"; }

.icon-right:before {
  content: "\e905"; }

.icon-twitter:before {
  content: "\e912"; }

.icon-menu:before {
  content: "\e913"; }

.icon-link:before {
  content: "\e9cb"; }

.icon-plus:before {
  content: "\ea0a"; }

.icon-minus:before {
  content: "\ea0b"; }

.icon-cross:before {
  content: "\ea0f"; }

.icon-check:before {
  content: "\ea10"; }

.icon-play:before {
  content: "\ea1c"; }

.icon-facebook:before {
  content: "\ea91"; }

.icon-linkedin:before {
  content: "\eac9"; }
