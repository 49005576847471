@import 'src/styles/core.sass';
.blog-slide-handler {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
  @include breakpoint(mobile) {
    padding: 10px 0; }
  .icon-left {
    left: 0; }
  .icon-right {
    right: 0; }
  .icon {
    pointer-events: auto;
    padding: 0 5px;
    cursor: pointer;
    font-size: $font-size-xl;
    color: $green;
    display: none;
    @include breakpoint($tablet) {
      background-color: rgba(255, 255, 255, 0.6);
      z-index: 10;
      top: 0;
      bottom: 0;
      width: 40px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        font-size: 1.5rem;
        font-weight: 700; } } }
  .dot {
    display: none;
    cursor: pointer;
    @include size(14px);
    border-radius: 100%;
    margin: 0 4px;
    background: $white;
    box-shadow: 0 0 0 1px $green;
    &.active {
      background-color: $green; } } }
