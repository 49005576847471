@import 'src/styles/core.sass';
#consent-banner {
  box-sizing: border-box;
  position: fixed;
  background-color: rgba(black, 0.8);
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  display: grid;
  place-items: center;
  .banner-card {
    box-shadow: 0 0 8px rgba(black, 0.15);
    background-color: $white;
    padding: 20px;
    max-width: $phablet; }
  h1 {
    font-size: $font-size-l;
    color: $red; }
  @include breakpoint(laptop) {
    font-size: $font-size-m; }
  .buttons {
    padding-bottom: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr; }
  button {
    border: none; }
  .button-accept {
    color: $white;
    background-color: $green; }
  .button-reject {
    background-color: $medium-gray;
    color: $dark-gray; }
  a strong {
    text-decoration: underline;
    color: $green; } }

@keyframes bannerEnter {
  0% {
    opacity: 0;
    transform: translateY(20%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }
