@import 'src/styles/core.sass';
#company {
  nav {
    position: sticky;
    top: 0;
    border-bottom: 1px solid #DEDEDE; }
  .history {
    padding: 1rem 0; }
  .company-name {
    user-select: none; }
  #header-company {
    .header-company-wrapper {
      position: relative;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: linear-gradient(89.96deg, $green 0.04%, $yellow 63.59%);
      background-position: center;
      aspect-ratio: 5 / 4;
      box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.4);
      display: grid;
      place-items: center;
      > picture {
        position: absolute;
        width: 100%;
        height: 100%;
        > img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
          filter: brightness(60%); } }
      .company-info {
        z-index: 10;
        color: $white;
        > span {
          color: $white;
          font-size: $font-size-l;
          font-weight: 600;
          text-shadow: 0 4px 4px rgba(black, 0.4); } }
      @include breakpoint($minWidth: 768px) {
        aspect-ratio: 4 / 1;
        justify-items: flex-start;
        padding: 10px 34% 10px 5%; } } }

  .scroll::-webkit-scrollbar {
    display: none; }
  .scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    > * {
      scroll-snap-align: start; } }
  .title {
    font-size: $font-size-l;
    font-weight: 600;
    @include breakpoint(tablet) {
      font-size: $font-size-xl; } }
  .categories {
    margin-top: 1em;
    .category-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 125.5%;
      color: #00937D; }
    &.only-desktop {
      display: none; }
    @include breakpoint($laptop) {
      .category-name {
        white-space: nowrap; }
      &.mobile {
        display: none; }
      &.only-desktop {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > div {
          display: flex;
          gap: .5rem;
          align-items: center; } } } }
  .video-container {
    display: flex;
    gap: 1rem;
    margin: 2rem auto;
    .carousel-wrapper {
      height: 200px; }
    .company-video-card {
      aspect-ratio: 16 / 9;
      flex: 1 0 80%;
      video {
        width: 100%;
        max-height: 100%;
        height: 200px; }
      @include breakpoint($laptop) {
        flex: 1 0 40%; } } }
  .contact-button {
    bottom: 30px;
    left: 0;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    right: 0;
    position: fixed;
    z-index: 10;
    background: #00937D;
    color: $white;
    &.opened {
      display: none; }
    @include breakpoint($laptop) {
      display: none; } }
  [id="company-services"] {
    > * {
      line-height: 2;
      color: #5E5E5E; }
    // h1, h2, h3, h4, h5, h6
    //   font-family: $blog-font-family
    h1 {
      font-weight: 600;
      font-size: $font-size-xl; }
    h2 {
      font-weight: 500;
      font-size: $font-size-l; }
    ul {
      padding-inline-start: 40px;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      li {
        list-style-type: disc;
        font-weight: 400; } } }
  [id="contact"] {
    .company-info {
      gap: 1.5rem;
      margin-bottom: 2rem;
      .image-wrapper {
        @include breakpoint(tablet) {
          @include size(90px); } }
      > span {
        font-size: $font-size-xl;
        font-weight: 600; } } }
  #principal {
    [id="services"], [id="contact"], [id="posts"] {
      border-top: 1px solid rgba($medium-gray, 0.4);
      padding-top: 30px;
      margin-top: 30px; } }
  .table-info {
    padding-bottom: 10px;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: $tablet;
    border: 1px solid #D7D7D7;
    > .top + .top {
      border-left: 1px solid #D7D7D7; }
    .top {
      border-bottom: 1px solid #D7D7D7; }
    .table-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      padding: 1rem 0.5rem;
      > .table-item-gray {
        font-weight: 500;
        color: #A2A2A2; }
      > .table-item-content {
        color: #1B1B1B;
        font-weight: 400; }
      > .table-item-content-p {
          font-size: 15px;
          color: #1B1B1B;
          font-weight: 300; }
      [id="company-date-modified"] {
        &::first-letter {
          text-transform: uppercase; } } }
    .date {
      grid-column: 1 / 2;
      grid-row: 1;
      font-size: 14px;
      line-height: 125.5%; }
    .employes {
      grid-column: 2 / 3;
      grid-row: 1;
      font-size: 14px;
      line-height: 125.5%; }
    .description {
      grid-column: 1 / 4;
      line-height: 160%; } }
  #services {
    > ul {
      > li {
        list-style: disc;
        list-style-position: inside;
        margin-top: 0.5rem;
        color: #5E5E5E;
        > ol {
          padding: 0;
          list-style-type: decimal; } } } }
  #posts {
    .blog-post-card {
      width: $phablet; }
    h4 {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px; }
    .show-post {
      display: block;
      text-align: right;
      text-decoration: underline;
      font-weight: 400;
      font-size: 16px;
      line-height: 125.5%; }
    .post-container {
      display: flex;
      margin: 2rem auto;
      gap: 1rem;
      overflow-x: scroll;
      .post-card {
        flex: 1 0 75%;
        > img {
          width: 100%;
          max-height: 148px; }
        > span {
          font-weight: 600;
          font-size: 15px;
          line-height: 125.5%;
          color: black; }
        @include breakpoint($laptop) {
          flex: 1 0 30%; } } } }
  @include breakpoint($laptop) {
    .posts-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      >h4 {
        margin: 0; } } }
  #separator {
    position: relative; }
  #form-contact {
    display: none; }
  section {
    padding: 0 1rem; }
  @include breakpoint($laptop) {
    section {
      padding: 0; }
    #separator {
      display: flex;
      gap: 2rem;
      #principal {
        width: 66.66%; }
      #form-contact {
        display: block;
        width: 33.33%; } } } }
