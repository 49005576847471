// Global styles

// These styles get included in the css bundle, but shouldn't
// get imported by any component.

@import 'core.sass';

@import 'assets/fonts';
@import 'assets/icons';

@import '~normalize.css/normalize';
@import '~@gluedigital/glue-styles/main';
@import '~@gluedigital/modal/css/modal';

@import 'base/layout';
