@import 'src/styles/core.sass';
[id="post-hero-banner"] {
  color: $white;
  height: 280px;
  @include breakpoint(tablet) {
    height: 380px; }
  .container {
    height: 100%;
    padding-top: 40px;
    display: grid;
    place-items: center;
    text-align: center;
    background-image: linear-gradient(90deg, #00957E 0%, #99B342 64.22%, #FDC71A 128.43%);
 }    // animation: animationGradient 5s ease infinite
  a:first-child {
    width: fit-content;
    display: block;
    margin: auto; }
  h1 {
    color: $white;
    max-width: $tablet;
    font-weight: 600;
    font-size: $font-size-l;
    @include breakpoint(laptop) {
      font-size: $font-size-xl; } }
  .image-wrapper {
    @include size(75px);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba($medium-gray, 0.5);
    background-color: $white;
    display: grid;
    place-items: center;
    overflow: hidden;
    margin: auto;
    img {
      width: 75%;
      max-height: 75%; } } }
@keyframes animationGradient {
  0% {
    background-position: 10% 0%; }
  50% {
    background-position: 91% 100%; }
  100% {
    background-position: 10% 0%; } }
