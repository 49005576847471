@import 'src/styles/core.sass';
#navbar {
  padding: 0 15px;
  z-index: 100;
  width: 100%;
  background-color: $white;
  position: fixed;
  top: 0;
  .beta-ribbon {
    right: 50px;
    position: absolute;
    color: $white;
    background-color: $red;
    padding: 2px 10px;
    pointer-events: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: $font-size-s;
    @include breakpoint(tablet) {
      font-size: $font-size-m;
      padding: 1px 12px; } }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    list-style-type: none;
    color: $white;
    padding: 0;
    > li {
      padding-top: 0;
      padding-bottom: 0;
      &.logo {
        img {
          width: 170px; } }
      &.item {
        display: none;
        width: 100%;
        text-align: start;
        order: 3;
        &#search {
          margin-top: 2rem;
          margin-bottom: 2rem; }
        &.info {
          span {
            font-size: 1rem;
            color: $white;
            line-height: 4rem;
            font-weight: 600;
            transition: color 0.5s;
            &:hover {
              color: #AFAFAF; } } }
        &.lens {
          .icon {
            font-size: 1.5rem; } }
        > a {
          &.current {
            span {
              color: #FDC71A; } } } } }
    .buttons {
      display: flex;
      gap: 1rem;
      color: $white;
      align-items: center;
      order: 1;
      .icon {
        cursor: pointer; }
      .icon-menu {
        font-size: 1.5rem; } }
    &.black {
      span {
        color: #2E2D2D; }
      >li {
        &.item {
          &.info {
            span {
              color: #2E2D2D; } } } } } }
  &.top {
    .menu {
      >li {
        &.lens {
          display: none; } } } }
  &.moved {
    background-color: $white;
    border-bottom: 1px solid #DEDEDE;
    .menu {
      >li {
        &.item {
          span {
            color: #2E2D2D;
            &.icon:not(.icon-lens) {
              color: white; } } } } }
    .buttons {
      color: #2E2D2D; } }
  @include breakpoint($maxWidth: 1079px) {
    &.open {
      height: 100vh;
      position: fixed;
      background-color: $green;
      .searchbar {
        max-width: $phablet; }
      .menu {
        > li {
          &.item {
            display: block;
            &.info {
              span {
                color: $white; } }
            &.hide {
              display: none; } }
          &.lens {
            display: none; } }
        .buttons {
          li {
            span {
              color: $white; } } } } } }

  @include breakpoint($minWidth: 1080px) {
    .menu {
      justify-content: space-between;
      gap: 10px;
      >li {
        &.item {
          padding: 0.5rem 20px;
          display: block;
          width: auto; }
        &.hide {
          display: none; }
        &.show {
          display: block;
          &.lens {
            .icon {
              &::before {
                content: '\ea0f'; } } } } }
      .buttons, #search {
        display: none; } }
    .logo {
      flex: 1;
      order: 0; } } }

