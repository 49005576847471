@import 'src/styles/core.sass';
.advice-categories {
  background-color: rgba($red, 0.2);
  color: $red;
  padding: 2px 6px; }
.input-min-services {
  visibility: hidden;
  height: 0; }
#categories-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  #category-select {
    width: 100%;
    display: flex;
    color: #344154;
    h4 {
      padding-left: 1rem; }
    .categories {
      width: 100%;
      border: 1px solid #E6E6E6;
      display: flex;
      flex-direction: column;
      flex: 0 0 50%;
      > span {
        padding: 10px 0 0 1rem;
        cursor: pointer; }
      >.selected {
        color: #00937D; }
      >.open {
        position: relative;
        width: 100%;
        padding-right: 0;
        background-color: #FAFAFA;
        &::after {
          border: 1.1rem solid transparent;
          height: 0;
          border-right: 0;
          border-left-color: #FAFAFA;
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          display: inline-block;
          margin-top: -1.1rem;
          margin-right: -1.1rem; }
        &::before {
          border: 1.25rem solid transparent;
          height: 0;
          border-right: 0;
          border-left-color: #E6E6E6;
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          display: inline-block;
          margin-top: -1.25rem;
          margin-right: -1.25rem; } } }
    .tags {
      width: 100%;
      background: #FAFAFA;
      display: flex;
      flex-direction: column;
      border: 1px solid #E5E5E5;
      flex: 0 0 50%;
      > label {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin: 4px 0;
        input {
          width: 1em;
          margin-left: 1.25rem;
          margin-top: 2px; } } } }

  #category-info {

    >h3 {
      margin: 0; }
    .tags {
      margin: 0.5rem 0;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      >span {
        font-weight: 500;
        font-size: 13px;
        line-height: 125.5%;
        color: #00937D; } } }
  @include breakpoint($laptop) {
    flex-direction: row;
    padding: 2rem;
    #category-select {
      width: 70%; }
    h5 {
      margin: 0; } } }
