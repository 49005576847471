@import 'src/styles/core.sass';
#category {
  position: relative;
  nav {
    background-color: $transparent;
    transition: all 0.5s ease-in-out; }
  section {
    padding: 1rem; }
  .categories-post-heading {}

  [id="categories-list"] {
    margin: 30px 0; } }
