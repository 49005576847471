#pages {
    overflow-x: auto;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    gap: 1rem;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      background: #F2F2F2;
      border-radius: 2px; }
    .active {
      color: white;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      background: #00937D;
      border: 1px solid #006959;
      border-radius: 2px; } }
