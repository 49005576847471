@import 'src/styles/core.sass';
.category-select-row {
  display: flex;
  width: 100%;
  gap: 12px;
  > span {
    > b {
      font-weight: 400;
      font-size: $font-size-m;
      color: $black; } }

  > input[type="checkbox"] {
    width: auto; } }
