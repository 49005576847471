@import 'src/styles/core.sass';
.category-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  >img {
    width: 70px;
    height: 70px; }
  >span {
    margin-top: 0.5rem;
    font-size: 16px;
    font-weight: 500;
    line-height: $heading-line-height;
    text-align: center; } }
