@import 'src/styles/core.sass';
.loading {
  margin: 10px auto;
  padding: 10px;
  height: 100%;
  max-height: 100vh;
  min-height: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeEnter 1s ease-in-out forward;
  .spinner {
    text-align: center;
    img {
      animation: rotation 1s linear infinite; } }
  .text {
    animation: blink 1s linear infinite; } }
@keyframes rotation {
  100% {
    transform: rotate(360deg); } }

@keyframes blink {
  100% {
    opacity: 0; } }
