@import 'src/styles/core.sass';
.searchbar {
  background-color: #F6F6F6;
  border-radius: 25px;
  position: relative;
  &.suggestions {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .searchbar-search {
    display: flex;
    position: relative;
    border-radius: 25px;
    button {
      border: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      padding: 0 10px;
      border-radius: 25px;
      margin: 6px;
      background: linear-gradient(90deg, #00957E 0%, #99B342 64.22%, #FDC71A 128.43%);
      color: white;
      .icon {
        font-size: 1.5rem;
        line-height: 1; }
      .text {
        display: none; }
      @include breakpoint(tablet) {
        padding: 0 1.5rem;
        .icon {
          font-size: 1.25rem;
          line-height: 1; }
        .text {
          display: block;
          margin-top: 2px; } } }
    input {
      border-radius: inherit;
      background-color: #F6F6F6;
      border: 0;
      margin: 0;
      padding: 14px 20px; } }
  .error {
    padding: 5px 10px;
    text-align: center;
    color: $red; }
  .searchbar-suggestions {
    border-radius: 25px;
    overflow-y: auto;
    background-color: white;
    padding: 0 1rem;
    position: absolute;
    height: fit-content;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    z-index: 200;
    background-color: rgb(246, 246, 246);
    h4 {
      color: black; }
    li {
      padding: .5rem 1.25rem;
      color: #3F3F3F;
      &.hovered {
        background-color: #00937D;
        font-weight: 600;
        cursor: pointer; } } } }
