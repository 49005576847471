@import 'src/styles/core.sass';
.tag-tooltip {
  overflow: visible;
  position: relative;
  .tooltip {
    display: none; }
  &:hover {
    .tooltip {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: max-content;
      max-width: 200px;
      position: absolute;
      bottom: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%);
      opacity: .95;
      background-color: #333030;
      mix-blend-mode: normal;
      padding: 10px;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top-color: #333030;
        border-bottom: 0;
        margin-left: -10px;
        margin-bottom: -10px; }
      span {
        color: $white;
        text-transform: uppercase;
        font-weight: 500;
        font-size: $font-size-s;
        line-height: 1.6; } } } }
