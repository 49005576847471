@import 'src/styles/core.sass';
[id="blog-post"] {
  section {
    padding: 0 1rem; }
  .blog-post-heading {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: $mobile;
    margin-top: 60px;
    background-image: linear-gradient(89.96deg, $green 0.04%, $yellow 63.59%); }
  .blog-post-info {
    padding-top: 50px;
    > h2 {
      font-weight: 400;
      font-size: $font-size-m;
      text-transform: uppercase; }
    > h1 {
      font-weight: 600;
      font-size: $font-size-xl; }
    > .blog-date-published {
      font-weight: 400;
      color: $medium-light-gray;
      font-size: $font-size-m; }
    > div {
      margin-top: .83em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .blog-post-company {
        flex-shrink: 0; }
      .blog-post-date-published {
        font-weight: 400;
        color: $medium-light-gray;
        font-size: $font-size-m; }
      .blog-post-links {
        border: 1px solid #DAD7D7;
        border-radius: 6px;
        padding: 6px 10px;
        flex-shrink: 0;
        display: flex;
        gap: 7px;
        > a {
          font-size: 1.4rem;
          &::before {
            color: $medium-light-gray; } } } } }
  .blog-post-content {
    font-family: $blog-font-family;
    > * {
      line-height: 2; }
    // h1, h2, h3, h4, h5, h6
    //   font-family: $blog-font-family
    h1 {
      font-weight: 600;
      font-size: $font-size-xl; }
    h2 {
      font-weight: 500;
      font-size: $font-size-l; }
    ul {
      padding-inline-start: 40px;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      li {
        list-style-type: disc; } } }

  [id="blog-posts-container-company"] {
    @include breakpoint(laptop) {
      width: 100%; }
    > div {
      display: flex;
      flex-direction: column;
      gap: 10px; }
    h1 {
      font-size: $font-size-l;
      @include breakpoint(tablet) {
        font-size: $font-size-m; }
      @include breakpoint(desktop) {
        font-size: $font-size-l;
        line-height: 1.4; } }
    h3 {
      font-weight: 400; } }

  @include breakpoint($tablet) {
    .blog-post-separator {
      display: flex;
      gap: 36px;
      justify-content: center;
      .blog-post-main-content {
        flex: 1 0 60%;
        max-width: 820px; }
      [id="blog-posts-container-company"] {
        margin-top: 2.5em;
        top: 40px;
        .blog-post-card-info {
          padding: 25px; }
        h1 {
          -webkit-line-clamp: 3; }
        > h3 {
          font-size: $font-size-m;
          text-align: center; }
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          > article {
            width: 288px;
            @include breakpoint(laptop) {
              width: 360px;
              &:nth-child(2) ~ article {
                display: none; } }
            @include breakpoint(desktop) {
              width: 420px; }
            &:nth-child(2) ~ article {
              display: none; } } } } }
    [id="blog-posts-container-related"] {
      .slide-cards {
        .arrow {
          height: 220px; }
        > ul {
          .blog-post-card {
            flex: 1 0 80%;
            @include breakpoint($tablet) {
              flex: 1 0 49%; } } } } } } }
