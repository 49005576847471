@import 'src/styles/core.sass';
#captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  >strong {
    font-weight: 600;
    font-size: 44px;
    line-height: 125.5%;
    color: #364543; }
  >input {
    border: 1px solid #DADADA;
    border-radius: 3px;
    width: 81px;
    height: 56px; } }
.captcha-text {
  font-size: $font-size-s;
  max-width: $tablet;
  .link {
    text-decoration: underline; }
  @extend %push-auto; }
.success-inscribe-form {
  color: $turquoise-p-ultralite; }
.error-inscribe-form {
  color: $magent; }
.success-inscribe-form, .error-inscribe-form {
  font-size: $font-size-m;
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 1em; }


