@import 'src/styles/core.sass';
.pill {
  background-color: #00937D;
  border-radius: 3px;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 10px;
  flex-shrink: 0;
  cursor: pointer;
  > .name {
    font-weight: 500;
    font-size: 13px; } }
