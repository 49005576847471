@import 'src/styles/core.sass';
[id="not-found"] {
  background-color: $primary-color;
  font-size: $font-size-s * 1.1;
  font-weight: normal;
  height: 100vh;
  display: flex;
  flex-direction: column;
  nav, footer {
    flex: 0 0 auto; }
  > .container {
    flex: 1 1 auto;
    max-width: $tablet;
    text-align: center;
    margin-top: 150px;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .button {
    background-color: $green;
    color: $white;
    margin-bottom: 40px; }
  @include breakpoint(laptop) {
    font-size: $font-size-m; } }
