@import 'src/styles/core.sass';
#representative {
  .input-min-representants {
    visibility: hidden; }
  .message-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 500;
      &.error {
        color: $red; } } }
  h5 {
    margin-top: 1.5rem;
    font-weight: 600;
    line-height: 125.5%;
    color: #364543;
    @include breakpoint($desktop) {
      font-size: 18px; } }
  textarea {
    height: 90px;
    min-height: 0;
    &::placeholder {
      color: #B6B6B6; }
    &:focus {
      border: 1px solid #B6B6B6;
      background-color: white; } }
  .dynamic-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    >div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .dynamic-inputs-delete {
        border: 1px solid #EE175B;
        background-color: #FFEEF5;
        align-self: flex-end;
        color: #EE175B;
        flex-shrink: 0;
        &.only-mobile {
          width: 100px;
          padding: 0.4rem; } }
      .dynamic-inputs-row {
        display: grid;
        gap: 1rem;
        margin: 0 auto;
        @include breakpoint($tablet) {
          grid-template-columns: repeat(2, 1fr); }
        @include breakpoint($laptop) {
          display: flex;
          .custom-select {
            flex-shrink: 0;
            max-width: 120px; }
          input {
            width: auto; }
          #telephone {
            flex: 1; } } }

      @include breakpoint($laptop) {
        flex-direction: row;
        align-items: center;
        .dynamic-inputs-delete {
          align-self: unset;
          width: 31px;
          height: 31px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &.only-mobile {
            display: none; } } } } }
  .add-dynamic-input {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 2rem;
    >div {
      width: 31px;
      height: 31px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      background: #EE175B;
      align-items: center;
      >span {
        color: white; } } }
  @include breakpoint($laptop) {
    padding: 2rem; } }
