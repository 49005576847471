@import 'src/styles/core.sass';
.categories-list {
  display: flex;
  margin: 30px 0;
  gap: 15px;
  overflow-x: auto;
  overflow-y: hidden;

  .carousel-wrapper {
    height: 140px;
    padding: 0 30px; }

  .category-card-link {
    flex-shrink: 0;
    max-width: 125px;
    text-align: center;
    > img {
      @include size(46px);
      display: block;
      margin: auto; }
    > span {
      display: block;
      margin-top: 15px;
      font-size: $font-size-s; }
    @include breakpoint(desktop) {
      max-width: 200px;
      > img {
        @include size(60px); }
      > span {
        margin-top: 20px;
        font-size: $font-size-m; } } } }
