@import 'src/styles/core.sass';
#home {
  position: relative;
  .top {
    scroll-snap-type: y mandatory; }
  nav {
    background-color: $transparent;
    transition: all 0.5s ease-in-out; }
  h2 {
    font-size: $font-size-xxl;
    line-height: $heading-line-height;
    margin-top: 3rem;
    margin-bottom: 3rem;
    @include breakpoint($desktop) {
      font-size: $font-size-xxxl; } }
  h3 {
    font-size: $font-size-xl;
    line-height: $heading-line-height;
    font-weight: 700;
    @include breakpoint($desktop) {
      font-size: $font-size-xxl; } }
  .lime {
    background-color: $lime; }
  section {
    padding: 4rem 1rem;
    @include breakpoint($tablet) {
      padding: 7rem 1rem; } }
  #heading {
    display: flex;
    align-items: center;
    min-height: $mobile;
    height: 90vh;
    max-height: $tablet;
    background-image: url("/images/home-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 100vh rgba(black, 0.6);
    .logo-certified {
      position: absolute;
      right: 10px;
      bottom: 10px;
      max-width: 180px;
      @include breakpoint(tablet) {
        max-width: 360px; } }
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3rem;
      position: relative; }
    h1 {
      max-width: 408px;
      color: $white;
      font-weight: 800;
      font-size: $font-size-xxl;
      line-height: $heading-line-height;
      margin: 0;
      @include breakpoint($laptop) {
        max-width: 700px;
        font-size: $font-size-xxxl; } }
    .searchbar {
      max-width: 408px;
      @include breakpoint($laptop) {
        max-width: $phablet; } } }
  #info {
    .info-container {
      font-size: $font-size-m;
      .info-container-description {
        line-height: 191.02%; }
      .info-container-values {
        font-weight: 400;
        line-height: 179.02%;
        .info-container-values-item {
          margin: 0.5rem 0;
          > div {
            display: none; } } }
      @include breakpoint($laptop) {
        display: flex;
        gap: 3rem;
        .info-container-description {
          h3 {
            margin-top: 10px; } }
        .info-container-values {
          max-width: 480px;
          strong {
            font-weight: 600; }
          .info-container-values-item {
            display: flex;
            gap: 1rem;
            > div {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 36px;
              height: 36px;
              border-radius: 50%;
              background-color: $light-green;
              border: 1px solid $green;
              > span {
                color: $green; } } } } } } }
  #video {
    h3 {
      font-size: 30px;
      color: #364543;
      max-width: 440px;
      font-weight: 700;
      line-height: 1.4em;
      span {
        line-height: 1.7;
        background-color: tint($green, 70%); }
      strong {
        font-weight: 400;
        font-style: italic; } }
    .video-card {
      width: 100%;
      height: 100%;
      video {
        width: 100%;
        height: 100%;
        object-fit: fill; } }
    .container {
      padding: 0; }
    @include breakpoint($tablet) {
      img {
        max-width: 70%; }
      .container {
        display: flex;
        gap: 5rem;
        align-items: center;
        justify-content: center;
        padding: 0 1rem; }
      .video-card {
        max-width: 544px; } } }
  .home-companies {
    text-align: center;
    .card-container {
      display: flex;
      gap: 20px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;
      > * {
        scroll-snap-align: start; }
      &::-webkit-scrollbar {
        display: none; }
      @include breakpoint(tablet) {
        display: grid;
        place-content: center;
        gap: 20px;
        grid-template-columns: repeat(3, $mobile); } } }
  .blog-posts {
    text-align: center;
    .card-container {
      display: flex;
      gap: 20px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;
      > * {
        scroll-snap-align: start; }
      &::-webkit-scrollbar {
        display: none; }
      @include breakpoint(tablet) {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, minmax($mobile, 1fr)); } } }
  #explore {
    > h2 {
      text-align: center;
      margin-bottom: 7rem; }
    .explore-items {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5rem; }
    @include breakpoint(laptop) {
      .explore-items {
        grid-template-columns: repeat(3, 1fr); } } }
  #blog, #recents {
    &.container {
      max-width: 1280px;
      margin: auto; } }
  #blog {
    .blog-posts-heading {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: space-between;
      a {
        display: block;
        text-decoration: underline; } } } }
