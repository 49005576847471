@import 'src/styles/core.sass';
.blog-post-card {
  min-width: $mobile;
  text-align: left;
  > a {
    @include size(100%);
    padding: 40px 25px;
    border-radius: 6px;
    box-shadow: inset 0 0 0 100vh rgba(black, 0.4);
    color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end; }
  h1 {
    font-size: $font-size-xl;
    font-weight: 600;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  h2 {
    font-size: $font-size-m;
    font-weight: normal;
    text-transform: uppercase; }
  .company-info {
    color: $white; } }
