html, body, [id="root"], [id="app"], .page {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth; }
[id="app"] {
  overflow: hidden;
  label {
    margin: 0; }
  input, select {
    background-color: $white;
    border: 1px solid $turquoise-p-lite;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 0;
    line-height: 1;
    width: 100%;
    outline: none;
    @include placeholder {
      color: $gray; }
    @include event {
      background-color: $white; } }
  select {
    color: $gray; }
  option {
    background-color: $white;
    color: $gray; }
  .page {
    padding: 0.1px;
    overflow: auto;
    background-color: $white; }

  .modal-background {
    z-index: 9;
    padding: 20px;
    animation: fadeEnter 0.3s ease-in-out; }
  .modal-foreground {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 10px; }

  .italic {
    font-weight: 400;
    font-style: italic; } }


.only-desktop {
  display: none;
  @include breakpoint(laptop) {
    display: inline-block; } }
.only-mobile {
  display: inline-block;
  @include breakpoint(laptop) {
    display: none; } }
// Data animation
@keyframes fadeEnter {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
