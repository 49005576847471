@import 'src/styles/core.sass';
.post-card {
  position: relative;
  color: $white;
  text-align: left;
  font-size: $font-size-m;
  max-height: 421px;
  border-radius: 4px;
  max-width: 285px;
  .post-card-image {
    position: relative;
    display: flex;
    border-radius: 4px;
    height: 220px;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    > div {
      color: $white;
      padding: 1rem;
      position: relative;
      background-image: linear-gradient($transparent, $black);
      h1 {
        margin: 0; }
      h1, p {
        font-size: $font-size-m;
        text-shadow: 0 4px 4px $black;
        margin-bottom: 0;
        font-weight: 600;
        line-height: $heading-line-height;
        @extend %ellipsis; }
      .post-card-name {
        font-weight: 400; } } }
  .post-card-category {
    color: $green;
    font-weight: 500; }
  @include breakpoint($laptop) {
    max-height: 455px; } }
