@import 'src/styles/core.sass';
[id="post-grid"] {
  max-width: $laptop;
  margin: auto;
  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px; }
  @include breakpoint($tablet) {
    .grid {
      display: grid;
      grid-auto-rows: 300px;
      gap: 10px; }
    .featured-grid {
      grid-template-columns: repeat(3, 1fr);
      .blog-post-card {
        &:first-child {
          grid-column: 1 / 4; }
        &:nth-child(3) {
          grid-column: 2 / 4; } } }
    .normal-grid {
      grid-template-columns: repeat(2, 1fr); } } }
