@import 'src/styles/core.sass';
.company-info {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  &.big-size {
    .image-wrapper {
      @include size(70px); } }
  .image-wrapper {
    @include size(45px);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba($medium-gray, 0.5);
    background-color: $white;
    flex-shrink: 0;
    display: grid;
    place-items: center;
    overflow: hidden;
    > img {
      width: 75%;
      max-height: 75%; } }
  > span {
    font-weight: 500; } }

