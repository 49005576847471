@import 'src/styles/core.sass';
[id="no-posts"] {
  background-color: $primary-color;
  font-size: $font-size-s * 1.1;
  font-weight: normal;
  > .container {
    text-align: center; }
  header {
    margin: 150px 0;
    height: 40vh;
    min-height: 200px;
    max-height: $phablet;
    display: grid;
    place-items: center;
    background-color: $medium-gray;
    text-align: center;
    p {
      font-size: $font-size-xl;
      text-transform: uppercase; } }
  .button {
    background-color: $green;
    color: $white; }
  @include breakpoint(laptop) {
    font-size: $font-size-m; } }
