@import 'src/styles/core.sass';
#results-search {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  nav, #header, footer, .help-results {
    flex: 0 0 auto; }
  nav {
    position: sticky;
    top: 0;
    border-bottom: 1px solid #DEDEDE; }
  section {
    padding: 0 1rem; }
  #header {
    >h2 {
      font-weight: 700;
      font-size: $font-size-xl;
      line-height: $heading-line-height;
      @include breakpoint($desktop) {
        font-size: $font-size-xxxl; } }
    #searchbar {
      max-width: 440px;
      @include breakpoint($laptop) {
        max-width: 522px; } }
    .history, #utils {
      margin-top: 1rem; }
    #utils {
      display: flex;
      flex-direction: column;
      gap: 15px;
      .custom-select {
        align-self: flex-start;
        width: 100%;
        max-width: 400px;
        .select-label {
          > strong {
            font-weight: 500;
            font-size: 15px;
            color: #000; } }
        ul {
          max-height: none;
          border-top: 0; }
        &:focus-within {
          .custom-select-header {
            border-bottom: 0; }
          ul {
            border-top: 0; } }
        .custom-select-header, ul {
          border: 1px solid #BEBEBE;
          border-radius: 3px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: 0;
          padding: 0.5rem 1rem;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); } }
      .category-pills-container {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap; }
      @include breakpoint($laptop) {
        align-items: center;
        flex-direction: row; } } }
  #companies {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #companies-grid {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: minmax(275, auto);
      gap: 1rem;
      @include breakpoint($phablet) {
        gap: 1rem;
        grid-template-columns: repeat(2, 1fr); }
      @include breakpoint($tablet) {
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr); }
      @include breakpoint($desktop) {
        gap: 2rem;
        grid-template-columns: repeat(4, 1fr); } } }
  .help-results {
    position: relative;
    display: block;
    width: 100%;
    margin: 4rem auto;
    font-weight: 400;
    font-size: 15px;
    line-height: 125.5%;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    > span {
      cursor: pointer; }
    .help-results-info {
      position: absolute;
      top: 40px;
      left: 50%;
      width: 80%;
      transform: translateX(-50%);
      @include breakpoint($tablet) {
        max-width: 500px; }
      >div {
        display: none;
        &.show {
          padding: 2rem;
          display: block;
          position: relative;
          background: white;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          border: 1px solid #E4E4E4;
          font-weight: 300;
          font-size: 13px;
          line-height: 130%;
          color: #555555; }
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 0;
          height: 0;
          border: 20px solid transparent;
          border-bottom-color: white;
          border-top: 0;
          margin-left: -20px;
          margin-top: -20px; }
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 0;
          height: 0;
          border: 21px solid transparent;
          border-bottom-color: #E4E4E4;
          border-top: 0;
          margin-left: -21px;
          margin-top: -21px; } } } } }
