@import 'src/styles/core.sass';
.blog-post-placeholder {
  background-color: $light-gray;
  min-height: 247px;
  border-radius: 6px;
  padding: 45px 25px;
  box-shadow: inset 0 0 0 100vh rgba(black, 0.4);
  display: flex;
  flex-direction: column;
  gap: 30px;
  .item {
    height: 24px;
    background-color: $medium-gray;
    border-radius: 6px; }
  .category {
    width: 40%; }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px; }
  .title {
    width: 90%; }
  .company-info {
    display: flex;
    align-items: center;
    width: 100%;
    .logo {
      width: 45px;
      height: 45px;
      border-radius: 50%; }
    .name {
      flex: 0 0 40%; } } }
