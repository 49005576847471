@import 'src/styles/core.sass';
[id="blog-hero-banner"] {
  color: $white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 100vh rgba(black, 0.6);
  height: 60vh;
  min-height: 380px;
  max-height: 720px;
  display: grid;
  align-items: center;
  cursor: pointer;
  .header-content {
    width: fit-content; }
  h1 {
    max-width: $tablet;
    font-weight: 600; }
  h2 {
    max-width: $tablet;
    font-weight: 400;
    text-transform: uppercase;
    font-size: $font-size-m; }
  .company-info {
    color: $white; }
  strong {
    font-weight: 500; }

  &.placeholder-banner {
    .logo {
      animation: blink 1s infinite;
      opacity: 0.6; }
    .placeholder-default {
      height: 1em;
      background-color: $gray;
      overflow: hidden;
      display: block;
      position: relative;
      &:not(strong) {
        max-width: 40%; }
      &::before {
        opacity: 0.5;
        content: '';
        position: absolute;
        left: -45%;
        height: 100%;
        width: 45%;
        background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
        animation: loading 1s infinite;
        z-index: 45; } }
    strong {
      width: 120px; } } }

@keyframes loading {
  0% {
    left: -45%; }

  100% {
    left: 100%; } }

@keyframes blink {
  40% {
    opacity: 0; }
  60% {
    opacity: 0; } }
