@import 'src/styles/core.sass';
.company-result {
  border: 1px solid #DDDDDD;
  border-radius: 3px;
  padding: 0.5rem;
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 142px;
    background-color: #F6F6F6;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    > img {
      width: 100%;
      height: 100%; } }
  .name {
    font-weight: 600;
    font-size: 14px;
    line-height: 125.5%;
    color: #353535; }
  .categories {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125.5%;
    color: #00937D; }
  .tags {
    padding-bottom: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    > li {
      border: 1px solid #BFBFBF;
      border-radius: 2px;
      font-weight: 400;
      font-size: 11px;
      color: #4B4B4B;
      padding: 0.2rem;
      &:not(.tag-tooltip) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 2.6em;
        line-height: 1.3em; } } }
  .name, .tags, .categories {
    padding-left: 1rem; } }
