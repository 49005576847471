@import 'src/styles/core.sass';
.tags-container {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  >li {
    border: 1px solid #BFBFBF;
    border-radius: 2px;
    font-weight: 400;
    font-size: 11px;
    line-height: 125.5%;
    color: #4B4B4B;
    padding: 0.2rem;
    &.see-more-tags {
      cursor: pointer; } } }
