@import 'src/styles/core.sass';
.carousel-wrapper {
  max-width: 100%;
  width: 100%;
  position: relative;
  height: auto;
  overflow: hidden;
  .slides {
    height: 100%;
    > * {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      flex: 0 0 auto;
      padding: 0 10px; } } }
