@import 'src/styles/core.sass';
.video-card {
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  aspect-ratio: 1.77;
  video {
    background-color: $black; }
  .play {
    background-color: rgba(#0E0E0E, 0.6);
    @include size(68px);
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-items: center;
    padding-left: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 1;
    &.hide {
      display: none; }
    &:hover {
      background-color: rgba(#0E0E0E, 0.8); } }
  .icon-play {
    font-size: 2rem;
    border-radius: 3px;
    color: white; } }
