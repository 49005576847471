@import 'src/styles/core.sass';
[id="contact-modal"] {
  overflow-y: auto;
  &.modal-background {
    z-index: 900; }
  @include breakpoint($maxHeight: 667px) {
    align-items: flex-start; }
  .modal-close {
    display: none; }
  .close-modal {
    color: red;
    line-height: 1;
    @include size(30px);
    padding: 1px;
    border: 2px solid $red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .modal-foreground {
    height: fit-content;
    max-width: $tablet;
    max-height: $tablet;
    padding: 0; }
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    >header {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 10px; }
    > section {
      padding: 1rem 0;
      > form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 0 1rem;
        .telephone-contact {
          display: flex;
          align-items: center;
          gap: 10px;
          .custom-select {
            flex: 0 0 40%;
            max-width: 150px;
            .flag {
              flex-shrink: 0;
              margin-right: 12px;
              & + span {
                margin-top: 4px; } }
            .custom-select-header {
              height: 100%;
              .custom-select-header-info, .custom-select-row {
                display: flex;
                align-items: center;
                padding: 5px 10px;
                span {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis; } } }
            .custom-select-list {
              span {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; } } } }
        input {
          margin: 0.15rem 0;
          border: 1px solid #DADADA; }
        textarea {
          border: 1px solid #DADADA;
          background-color: $white;
          max-height: 150px;
          min-height: 7vh;
          padding: 10px;
          margin: 0.15rem 0;
          &:active {
            background-color: $white; }
          &::placeholder {
            color: $gray; } }
        > .check {
          text-align: justify;
          font-size: $font-size-s;
          input[type="checkbox"] {
            width: fit-content;
            margin-right: .25rem; } }
        > footer {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 10px;
          justify-content: space-between;
          button {
            border-radius: 30px;
            padding: 0.75rem 2rem;
            font-weight: 600;
            font-size: 16px;
            line-height: 125.5%;
            color: #FFFFFF;
            background: linear-gradient(90deg,#00957E 0%, #99B342 64.22%, #FDC71A 128.43%);
            &:only-child {
              margin-left: auto; } } } } } } }
