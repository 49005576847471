#footer-form {
  display: flex;
  justify-content: space-between;
  button {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 125.5%;
    color: #FFFFFF;
    background: linear-gradient(90deg, #EC0163 0%, #F45F40 61.54%, #FDC71A 128.43%);
    border-radius: 30px;
    border-color: black;
    padding: 0.75rem 1.75rem;
    border: 0; }
  .back {
    background: linear-gradient(-90deg, #EC0163 0%, #F45F40 61.54%, #FDC71A 128.43%); }
  .back:disabled {
    opacity: 0; } }
