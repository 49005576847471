@import 'src/styles/core.sass';
.company-video-card {
  position: relative;
  overflow: hidden;
  video {
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid tint($medium-gray, 60%); }
  .video-title {
    display: flex;
    align-items: flex-end;
    color: white;
    font-weight: 600;
    font-size: 15px;
    line-height: 125.5%;
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 1px;
    top: 1px;
    box-shadow: inset 0 0 0 100vh rgba(black, 0.25);
    padding: 10px;
    border-radius: 6px;
    text-shadow: 0 4px 4px rgba(black, 0.3);
    &.hide {
      display: none; } } }
