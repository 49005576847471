.history {
  padding-left: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  > * {
    font-weight: 400;
    font-size: 12px;
    line-height: 125.5%;
    color: #354543;
    &:first-child {
      padding-left: 0; }
    &:not(:first-child) {
      &::before {
        margin: 0 .4rem;
        content: '';
        transform: rotate(315deg);
        border: solid gray;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px; } } } }
