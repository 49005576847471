@import 'src/styles/core.sass';
#captcha-company {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  background-color: $light-gray;
  border-radius: 6px;
  > strong {
    font-weight: 600;
    color: #364543; }
  > input {
    border: 1px solid #DADADA;
    border-radius: 3px;
    width: 70px; } }
